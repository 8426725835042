import React, { useState, useEffect } from "react"
import CardView from "./CardView"

import userIcon from "../../../../images/per_squre.svg"
import heartIcon from "../../../../images/heart_squre.svg"
import clipIcon from "../../../../images/clip_icon.svg"
import revIcon from "../../../../images/rev_icon.svg"
import { useSelector } from "react-redux"
function TabItem({ value }) {
  const [data, setData] = useState({})

  const clubInfoStats = useSelector(
    state => state.userSlice.user?.userInfo?.statistics
  )

  const caclculateStats = name => {
    let temp = { ...clubInfoStats }
    let tempValue = { ...temp[name] }
    let current = tempValue[`current${value}`]
    let previous = tempValue[`previous${value}`]

    let percentage
    const max = Math.max(previous, current)
    const min = Math.min(previous, current)
    if (previous != 0) {
      percentage = (min / max) * 100
    } else {
      percentage = 100
    }
    let showPercentage = true
    if (
      (previous == 1 && current == 0) ||
      (previous == 0 && current == 0) ||
      value == "allTime"
    ) {
      showPercentage = false
    }

    return {
      countLess: value == "allTime" ? true : current < previous ? true : false,
      percentage: percentage ? percentage.toFixed(0) : "-",
      showPercentage,
      count: value == "allTime" ? tempValue?.allTime : current,
    }
  }

  useEffect(() => {
    if (clubInfoStats) {
      let temp = {}
      Object.keys(clubInfoStats).map(key => {
        let stats = caclculateStats(key)
        temp = { ...temp, [key]: stats }
      })

      setData(temp)
    }
  }, [])

  return (
    <>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "50%", marginRight: "-43px" }}>
          <CardView
            logo={clipIcon}
            digit={data["bookings"]?.count}
            grayTitle="Bookings"
            showTagLine={data["bookings"]?.showPercentage}
            isTagGreen={data["bookings"]?.countLess ? false : true}
            tagLine={`${data["bookings"]?.countLess ? "-" : "+"} ${
              data["bookings"]?.percentage
            }% this ${value}`}
          />
        </div>
        <div style={{ width: "50%" }}>
          <CardView
            logo={userIcon}
            digit={data["userVisits"]?.count}
            grayTitle="Profile visitors"
            showTagLine={data["userVisits"]?.showPercentage}
            isTagGreen={data["userVisits"]?.countLess ? false : true}
            tagLine={`${data["userVisits"]?.countLess ? "-" : "+"} ${
              data["userVisits"]?.percentage
            }% this ${value}`}
          />
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "50%", marginRight: "-43px" }}>
          <CardView
            logo={heartIcon}
            digit={data["clubLikes"]?.count}
            grayTitle="Club likes"
            showTagLine={data["clubLikes"]?.showPercentage}
            isTagGreen={data["clubLikes"]?.countLess ? false : true}
            tagLine={`${data["clubLikes"]?.countLess ? "-" : "+"} ${
              data["clubLikes"]?.percentage
            }% this ${value}`}
          />
        </div>
        <div style={{ width: "50%" }}>
          <CardView
            logo={revIcon}
            grayTitle="Revenue"
            digit={
              data["revenue"]?.count
                ? data["revenue"]?.count +
                  " " +
                  clubInfoStats["revenue"]?.currency
                : ""
            }
            showTagLine={data["revenue"]?.showPercentage}
            isTagGreen={data["revenue"]?.countLess ? false : true}
            tagLine={`${data["revenue"]?.countLess ? "-" : "+"} ${
              data["revenue"]?.percentage
            }% this ${value}`}
          />
        </div>
      </div>
    </>
  )
}

export default TabItem
